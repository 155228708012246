<template>
  <b-card style="height: 50rem;">
    <b-card-header class="pb-50">
      <b-card-title>Report</b-card-title>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-success"
          class="btn-icon rounded-circle"
        >
          <feather-icon icon="PlusIcon" />
        </b-button> -->
    </b-card-header>
    <br>
    <b-card-body>
      <b-row>
        <b-col cols="12">
          <b-card no-body>
            <b-card-header>
              <b-card-title>List Keyport</b-card-title>
              <!-- <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="cursor-pointer"
              /> -->
            </b-card-header>
            <b-card-body>
              <b-list-group>

                <vue-perfect-scrollbar
                  :settings="perfectScrollbarSettings"
                  class="user-chats scroll-area"
                  style="height: 30rem;"
                >
                  <b-list-group-item v-for="item in data" :key="item.port">
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1 text-white">
                        Keyport: {{item.keyport}}
                      </h5>
                      <small class="text-secondary">{{item.ports}} port</small>
                    </div>
                    <b-card-text class="mb-1">
                      Total Bandwidth: {{formatBytes(item.bandwidth)}}
                    </b-card-text>
                    <small class="text-secondary">Total Request: {{item.request}}</small>
                    
                  </b-list-group-item>
              </vue-perfect-scrollbar>
              </b-list-group>
            
            </b-card-body>
            
          </b-card>
          
        </b-col>
        <!-- <b-col cols="12">
          <b-card no-body>
            <b-card-header>
              <b-card-title>Keyport: {{selectKeyport}}</b-card-title>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="cursor-pointer"
              />
            </b-card-header>
            <b-card-body >
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :per-page="perPage"
                :current-page="currentPage"
                :items="ports"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                style="height: 30rem;"
              >
              <template #cell(bandwidth)="data">
                {{formatBytes(data.item.bandwidth)}}
              </template>
              </b-table>
            </b-card-body>
          </b-card>

        </b-col> -->
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BOverlay, BCard, BTooltip, BCardHeader, BCardTitle, BCol,BRow, BForm,
  BFormTextarea, BListGroup, BListGroupItem, BMedia, BMediaAside, BMediaBody, BCardText
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import RepositoryFactory from '../../../api/RepositoryFactory'
let NPORT = RepositoryFactory.get('snport')

export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BCard,
    BTooltip,
    BCardHeader,
    BCardTitle,
    BCol,BRow,
    BForm,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BMedia, BMediaAside, BMediaBody,
    VuePerfectScrollbar,
    BCardText
  },
  data(){
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 10,
        wheelPropagation: false,
      },
      data: [],
      ports:[],
      fields: [
        { key: 'port', label: 'port', sortable: true },
        { key: 'bandwidth', label: 'Bandwith', sortable: true },
        { key: 'request', label: 'requests', sortable: true },
      ],
      proxies: [],
      variant: 'dark',
      opacity: 0.85,
      blur: '2px',
      perPage: 20,
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      selectKeyport: ""
    }
  },
  directives: {
    Ripple,
  },
  created(){
    NPORT.fetch().then(rs=>{
      this.proxies = rs.data.data  
      //console.log(this.proxies)
      let f = this.proxies.map(x=>x.keyport).filter((x,y)=>{
        return this.proxies.map(x=>x.keyport).indexOf(x) == y
      });
      f.forEach(element => {
        let e = this.proxies.filter(x=>x.keyport == element)
        let bw = e.map(x=>x.bandwidth).reduce((y,z)=>{return y+z})
        let rq = e.map(x=>x.request).reduce((y,z)=>{return y+z})
        this.data.push({
          keyport: element,
          bandwidth: bw,
          request: rq,
          ports: e.length
        })
      });
      this.totalRows = this.proxies.length
    }).catch(e=>{

    })
  },
  methods:{
    groupBy(list, keyMapper){
      return list.reduce((accummalatorMap, currentValue) => {
        const key = keyMapper(currentValue);
        if(!accummalatorMap.has(key)) {
          accummalatorMap.set(key, [currentValue]);
        } else {
          accummalatorMap.set(key, accummalatorMap.get(key).push(currentValue));
        }
        return accummalatorMap;
      }, new Map());
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getItem(k){
      this.selectKeyport = k
      this.ports = this.proxies.filter(x=>x.keyport == k)
    },
    formatBytes(bytes) {
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(bytes < kiloBytes) return bytes + " Bytes";
      // return KB if less than a MB
      else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB  ``
      else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
      // return GB if less than a TB
      else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
      
      else return (bytes / teraBytes).toFixed(decimal) + " TB";
    }
  }
}
</script>

<style>

</style>
