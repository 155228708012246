<template>
  <div>
    <b-alert
        v-height-fade.appear
        variant="danger"
        show
        class="mb-1"
      >
        <div class="alert-body">
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
          />
          Important: You will lost {{price}}$ for create or clone port
        </div>
      </b-alert>
    <manager></manager>
    <white-ip></white-ip>
    <report></report>
  </div>
</template>

<script>
import { BCard, BCardText,BTab,BTabs, BSpinner ,BButton, BAlert} from 'bootstrap-vue'

import manager from './Manager.vue'
import report from './Report.vue'
import whiteip from './WhiteIP.vue'

import { heightFade } from '@core/directives/animations'

export default {
  components: {
    BCard,
    BCardText,
    BTab,
    BTabs,
    BSpinner,
    BButton,
    BAlert,
    'manager': manager,
    'report': report,
    'white-ip': whiteip
  },
  data(){
    return {
      price: this.$store.getters['auth/settings'].price_per_port
    }
  },
  directives: {
    'height-fade': heightFade,
  },
  created(){

  }
}
</script>

<style>

</style>
