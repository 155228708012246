<template>
  <b-card>
    <b-card-header class="pb-50">
      <b-card-title>Proxy List</b-card-title>
      <!-- <b-card-actions>
        
      </b-card-actions> -->
      <div class="demo-inline-spacing">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="showModal(null)">
              Create Port
            </b-button>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-toggle.collapse-1 variant="outline-success">
                Settings
            </b-button>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" @click="onRenewal">
                Renewal
            </b-button>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" @click="onExport">
                Export
            </b-button>
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" @click="onHidePort">
              Delete
            </b-button>
        </div>
    </b-card-header>
    
    <b-card-body>
      <b-collapse id="collapse-1" class="mt-2">
          <b-card class="border mb-0">
            <b-card-text class="card-text">
              Apply setting for: <b-badge pill variant="success" v-for="a in select_port" :key="a">{{a}}</b-badge>
            </b-card-text>

            <b-card-body>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Key Port"
                    label-for="h-first-name"
                    label-cols-md="3"
                  >
                    <b-input-group>
                      <b-form-input
                        id="h-first-name"
                        placeholder="Name"
                        v-model="settings.keyport"
                      />
                      <b-input-group-append>
                        <b-button variant="outline-primary" @click="onChangeKeyPort">
                          Change
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    
                  </b-form-group>
                </b-col>
                <b-col cols="6" class="mb-1">
                  <b-form-group
                    label="Geo"
                    label-for="h-first-name"
                    label-cols-md="3"
                  >
                    <b-input-group>
                      <b-form-select
                        v-model="settings.country"
                        :options="country"
                        @change="changeCtSett(settings.ctsett,settings.country)"
                      />
                      <b-form-select
                        v-model="settings.ctsett"
                        :options="listgeo"
                        @change="changeCtSett(settings.ctsett,settings.country)"
                      />

                      <b-form-select
                        v-if= "settings.ctsett == 1"
                        v-model="settings.state"
                        :options="lstate"
                      />

                      <b-form-select
                        v-else-if= "settings.ctsett == 2"
                        v-model="settings.city"
                        :options="lcity"
                      />


                      <b-input-group-append>
                        
                        <b-button variant="outline-primary" @click="onChangeGeo">
                          Change
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    
                    
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group
                    label="Rotate"
                    label-for="h-first-name"
                    label-cols-md="3"
                  >
                    <b-input-group>
                      <b-form-spinbutton
                        v-model="settings.rotate"
                        min="1"
                        max="30"
                      />
                      <b-input-group-append>
                        <b-button variant="outline-primary" @click="onChangeRoatate">
                          Change
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    
                  </b-form-group>
                </b-col>
               
                <!-- <b-col cols="6">
                  <b-form-group
                    label="Type"
                    label-for="h-first-name"
                    label-cols-md="3"
                  >
                    <b-form-select
                      v-model="settings.proxy_type"
                      :options="proxy_type"
                    />
                  </b-form-group>
                </b-col> -->

                <b-col cols="6">
                  <b-form-group
                    label="Services"
                    label-for="h-first-name"
                    label-cols-md="3"
                  >
                    <b-row>
                      <b-col cols=8>
                        <!-- <b-badge  v-for="data in settings.services" :key="data.name">{{data.name}}</b-badge> -->
                        <b-badge
                          variant="success"
                          v-for="data in tempPost.services" 
                          :key="data.name"
                        >
                          <feather-icon
                            :icon="data.bypass ? 'CheckIcon' : ''"
                            class="mr-25"
                          />
                          <span>{{data.name}}</span>
                        </b-badge>
                      </b-col>
                      <b-col cols=2>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          class="btn-icon"
                          @click="showModalServices"
                        >
                          <feather-icon icon="PlusIcon" />
                        </b-button>
                        
                      </b-col>
                      <b-col cols=2>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          class="btn-icon"
                          @click="onChangeServices"
                        >
                          <feather-icon icon="SaveIcon" />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <div class="demo-inline-spacing">
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-success" @click="onStatusChange(true)">
                        Enable Status
                    </b-button>
                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" @click="onStatusChange(false)">
                        Disable Status
                    </b-button>
                  </div>
                </b-col>

                <!-- <b-col cols="6">
                  <b-form-group
                    label="Status"
                    label-for="h-first-name"
                    label-cols-md="3"
                  >
                    <b-form-checkbox
                      v-model="settings.status"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                      @change="onStatusChange"
                    />
                  </b-form-group>
                </b-col> -->
              </b-row>
            </b-card-body>
            <!-- <b-card-footer>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="onApply()">
                Apply
              </b-button>
            </b-card-footer> -->
          </b-card>
      </b-collapse>
    </b-card-body>

    <b-card-body>
     
      <div class="d-flex justify-content-between  flex-wrap">

        <div class="demo-inline-spacing">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="selectAll()">
            Select All On Page
          </b-button>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'"  variant="outline-primary" @click="clearSelect()">
            Clear Select
          </b-button>
      </div>
        <!-- sorting 
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group> -->

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
    <b-table
      striped
      hover
      ref="table"
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="proxies"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >

      <template #cell(select)="data">
        <b-form-checkbox  v-model="data.item.selected" @change="onSelected"></b-form-checkbox>
      </template>

      <template #cell(services)="data">
        <!-- <b-badge :variant="s.bypass ? 'success' : 'warning'" v-for="s in data.item.services" :key="s.name">{{s.name}}</b-badge> -->
        <b-badge
          variant="success"
          v-for="data in data.item.services" 
          :key="data.name"
        >
          <feather-icon
            :icon="data.bypass ?  'CheckIcon' : ''"
            class="mr-25"
          />
          <span>{{data.name}}</span>
        </b-badge>
      </template>

      <template #cell(rotate)="data">
        <b-badge variant="success">{{data.item.rotate}} minutes</b-badge>
      </template>

      <template #cell(status)="data">
        <b-badge :variant="data.item.status ? 'success' : 'danger'">{{ data.item.status ? 'On' : 'Off'}}</b-badge>
      </template>

      <template #cell(bandwidth)="data">
        {{formatBytes(data.item.bandwidth)}}
      </template>

      <template #cell(country)="data">
        <b-badge variant="success">{{ data.item.country}}</b-badge>
      </template>

      <template #cell(expired)="data">
        <b-badge variant="success">{{ getDate(data.item.expired)}}</b-badge>
      </template>

      <template #cell(ctsett)="data">
        <b-badge v-if="data.item.ctsett==1" variant="success">State {{ data.item.state}}</b-badge>
        <b-badge v-else-if="data.item.ctsett==2" variant="success">City: {{ data.item.city}}</b-badge>
        <b-badge v-else variant="success">All</b-badge>
      </template>

      <template #cell(_id)=data>
        <div class="text-nowrap">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            v-b-tooltip.hover.top="'Change Ip'"
            @click="changeIp(data.item)"
          >
            <feather-icon icon="RotateCwIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-warning"
            class="btn-icon rounded-circle"
            v-b-tooltip.hover.top="'Clone'"
            @click="clonePort(data.item)"
          >
            <feather-icon icon="CopyIcon" />
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="deleteAccount(data.item._id)"
            v-b-tooltip.hover.top="'Delete'"
          >
            <feather-icon icon="TrashIcon" />
          </b-button> -->
        </div>
        <div class="text-nowrap">
        
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    <b-modal
      id="modal-edit-account"
      ok-title="Create"
      cancel-title="Cancel"
      size="lg"
      title="Create Port"
      @ok="onSubmit"
      no-close-on-backdrop
    >
      <b-form @submit.prevent>
        <b-row>

          <b-col cols="12">
            <b-form-group
              label="Key Port"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-input-group>
              <b-form-input
                id="h-first-name"
                placeholder="API Key"
                disabled
                v-model="tempPost.keyport"
              />
              <b-input-group-append>
                <b-button variant="outline-primary" @click="onRandomKeyport">
                  Random
                </b-button>
              </b-input-group-append>
            </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Geo"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-input-group>
                <b-form-select
                  v-model="tempPost.country"
                  :options="country"
                  @change="changeCtSett(tempPost.ctsett,tempPost.country)"
                />
                <b-form-select
                  v-model="tempPost.ctsett"
                  :options="listgeo"
                  @change="changeCtSett(tempPost.ctsett,tempPost.country)"
                />

                <b-form-select
                  v-if= "tempPost.ctsett == 1"
                  v-model="tempPost.state"
                  :options="lstate"
                />

                <b-form-select
                  v-else-if= "tempPost.ctsett == 2"
                  v-model="tempPost.city"
                  :options="lcity"
                />                    
              </b-input-group> 
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Rotate"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-form-spinbutton
                v-model="tempPost.rotate"
                min="1"
                max="30"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Services"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <!-- <b-row v-for="data in settings.services" :key="data.name">
                <b-col cols="6">
                  <b-form-checkbox  v-model="data.selected">{{data.name}}</b-form-checkbox>
                </b-col>
                <b-col cols="6">
                  <b-form-checkbox  v-model="data.bypass" :disabled="!data.selected">Bypass</b-form-checkbox>
                </b-col>
              </b-row> -->
              <b-row>
                <b-col cols=10>
                  <!-- <b-badge  v-for="data in settings.services" :key="data.name">{{data.name}}</b-badge> -->
                  <b-badge
                    variant="success"
                    v-for="data in tempPost.services" 
                    :key="data.name"
                  >
                    <feather-icon
                      :icon="data.bypass ? 'CheckIcon' : ''"
                      class="mr-25"
                    />
                    <span>{{data.name}}</span>
                  </b-badge>
                </b-col>
                <b-col cols=2>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    @click="showModalServices"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          
        </b-row>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-services"
      ok-title="Done"
      cancel-title="Close"
      size="lg"
      title="Select Services"
      @ok="onSVSubmit"
      no-close-on-backdrop
    >
      <b-row>
          <b-col cols="12">
            <b-form-group
              label="Services"
              label-for="h-first-name"
              label-cols-md="3"
            >
              <b-row v-for="data in services" :key="data.name">
                <b-col cols="6">
                  <b-form-checkbox  v-model="data.selected">{{data.name}}</b-form-checkbox>
                </b-col>
                <b-col cols="6">
                  <b-form-checkbox  v-model="data.bypass" :disabled="!data.selected">Bypass</b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BOverlay, BCard, BTooltip, BCardHeader, BCardTitle, BCol,BRow, BForm,
  BFormTextarea,BFormCheckbox,BFormSpinbutton ,BCollapse, VBToggle, BCardFooter, BCardText, VBTooltip, BFormRadioGroup, BAlert
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import RepositoryFactory from '../../../api/RepositoryFactory'
let NPORT = RepositoryFactory.get('snport')
import { heightFade } from '@core/directives/animations'

import services_ from '../../../assets/data/services'
import targetCity from '../../../assets/data/targetCity'
import targetState from '../../../assets/data/targetState'

const tempObj = {
  "_id": null,
  "port": 10001,
  "nameport": "",
  "keyport": "",
  "country": "US",
  "rotate": 5,
  "proxy_type": "socks",
  "ctsett": 0,
  "state": "",
  "city": "",
  "services": [
    {
      name: 'AccessGoogle',
      bypass: false
    },
    {
      name: 'Apple',
      bypass: false
    },
  ],
  "numport": 10
}

export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BCard,
    BTooltip,
    BCardHeader,
    BCardTitle,
    BCol,BRow,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BFormSpinbutton,
    BCollapse,
    BCardFooter,
    BCardText,
    vSelect,
    BFormRadioGroup, 
    BAlert
  },
  data(){
    return {
      proxies: [],
      
      settings: {
        keyport: '',
        country: 'US',
        rotate: 5,
        proxy_type: 'socks',
        ctsett: 0,
        state: '',
        city: '',
        services: [ 
          {
            name: 'AccessGoogle',
            bypass: false
          },
          {
            name: 'Apple',
            bypass: false
          },
        ],
        status: true
      },

      tempPost: Object.assign({},tempObj),
      select_port: [],

      variant: 'dark',
      opacity: 0.85,
      country: this.$store.getters['auth/settings'].geos_avail.sort(),
      blur: '2px',
      perPage: 20,
      pageOptions: [2,5, 10, 20, 50, 100,200,500,1000],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      proxy_type: ['socks','https'],
      services: Object.values(Object.assign({},services_)),

      fields: [
        'select',
        { key: 'port', label: 'port', sortable: true },
        //{ key: 'nameport', label: 'name port', sortable: true },
        { key: 'country', label: 'Country', sortable: true },
        { key: 'ctsett', label: 'Region', sortable: true },
        { key: 'rotate', label: 'rotate', sortable: true },
        { key: 'bandwidth', label: 'Bandwith', sortable: true },
        { key: 'services', label: 'services', sortable: true },
        { key: 'expired', label: 'Expired', sortable: true },
        'status',
        { key: '_id', label: 'Action' },
      ],

      listgeo: [
        {
          "text": "All (Default)",
          value: 0,
        },
        {
          text: "State",
          value: 1,
        },
        {
          text: "City",
          value: 2,
        }
      ],
      selected: "All (Default)",
      targetState: Object.assign({},targetState),
      lstate: [],
      lcity: [],

    }
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
    Ripple,
  },
  created(){
    console.log(this.country)
    this.$gtag.event('route', {state: 'list-ports'})
    NPORT.fetch().then(rs=>{
      this.proxies = rs.data.data  
      this.totalRows = this.proxies.length
      //console.log(this.proxies)
    }).catch(e=>{

    })
    //console.log(this.country)
    this.proxies.forEach(element => {
      element.selected = false
    });
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
   

    
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.proxies.length
    this.select_port = this.proxies.filter(x=>x.selected).map(x=> x.port)
  },
  methods: {
    generateString(){
      const strs = "qwertyuioplkjhgfdsazxcvbnmQWERTYUIOPLKJHGFDSAZXCVBNM0123456789!@#$%^&*()_+=";
      var str = "";
      for (let index = 0; index < 8; index++) {
          str += strs[Math.floor(Math.random() * strs.length)];
      }
      return str;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onSelected(){
      this.select_port = this.proxies.filter(x=>x.selected).map(x=> x.port)
      if(this.select_port.length > 0){

        let data = this.proxies.filter(x=>x.selected)[0]
        //console.log(data)
        this.settings.keyport = data.keyport
        this.settings.country = data.country
        this.settings.rotate = data.rotate
        this.settings.services = data.services
        this.tempPost.services = data.services
        this.settings.ctsett = data.ctsett ? data.ctsett : 0
        this.settings.state = data.state
        this.settings.city = data.city
        this.changeCtSett(this.settings.ctsett,this.settings.country)
        
      }else{
        this.settings.keyport = ""
        this.settings.country = "US"
        this.settings.rotate = 5
        this.settings.ctsett = 0
        this.settings.services = [
        {
            name: 'AccessGoogle',
            bypass: false
          },
          {
            name: 'Apple',
            bypass: false
          },
        ]
        this.tempPost.services = [
        {
            name: 'AccessGoogle',
            bypass: false
          },
          {
            name: 'Apple',
            bypass: false
          },
        ]

        // this.services = [
        // {
        //     name: 'AccessGoogle',
        //     bypass: false
        //   },
        //   {
        //     name: 'Apple',
        //     bypass: false
        //   },
        // ]
 
        // this.services = Object.values(Object.assign({},services)),
        // this.services.forEach(x=>{
        //   if(x.name != "AccessGoogle" && x.name != "Apple"){
        //     x.selected = false
        //     x.bypass = false
        //   }else{
        //     x.selected = true
        //     x.bypass = false
        //   }
        // })
      }
      // let index =  Math.random() * (15 - 6) + 6
      // this.settings.keyport =  (+new Date).toString(36).slice(-index)

      //console.log(this.settings)
    },
    showModal(id){
      this.tempPost = Object.assign({}, tempObj)
      
      let index =  Math.random() * (15 - 6) + 6
      this.tempPost.keyport = (+new Date).toString(36).slice(-index)+this.generateString()
      this.tempPost.nameport = (+new Date).toString(36).slice(-6)+'_1'
      this.tempPost.city = this.tempPost.state = ''
      this.changeCtSett(0,this.tempPost.country)
      this.$bvModal.show('modal-edit-account');
    },
    showModalServices(){
      this.services = Object.values(Object.assign({},services_))
      this.services.forEach(x=>{
        x.selected = false
        x.bypass = false
      })
      //let sl1 = this.proxies.filter(x=>x.selected)[0]
      //console.log(this.tempPost.services)
      //this.services = sl1.services

      //console.log(this.services)
      this.tempPost.services.forEach(x=>{
        let found = this.services.findIndex(y=>y.name == x.name)
        if(found != -1){
          //console.log(found)
          this.services[found].selected = true
          this.services[found].bypass = x.bypass
        }
      })


      this.$bvModal.show('modal-services');
    },

    onSVSubmit(event){
      event.preventDefault()
      this.tempPost.services = this.services.filter(x=>x.selected)
      //console.log(this.$bvModal)
      
      this.$nextTick(() => {      
        this.$bvModal.hide('modal-services');
      })
    },
    onSubmit(event){
      event.preventDefault()
      // let curPost = this.tempPost.port
      // let listPorts = this.proxies.map(x=>x.port)
      // //console.log(listPorts)
      // let index = 1
      // let params = []
      // for(let i = 1; i <= this.tempPost.numport; i++){
      //   if(!listPorts.includes(curPost.toString())){
      //     params.push({
      //       port: curPost,
      //       keyport: this.tempPost.keyport,
      //       nameport:  this.tempPost.nameport+'_'+index,
      //       country: this.tempPost.country,
      //       rotate: this.tempPost.rotate,
      //       proxy_type: this.tempPost.proxy_type,
      //       services: this.settings.services.filter(x=>x.selected),
      //       status: true
      //     })
      //     index++
      //   }else
      //     i--
      //   curPost++
      // }
      
      NPORT.addPort(this.tempPost).then(rs=>{
        if(rs.data.success){
          this.proxies.push(rs.data.data)
          this.$bvModal.hide('modal-edit-account');
          this.totalRows = this.proxies.length
          this.$store.dispatch('auth/get_uinfo')
        }else{
          this.$bvToast.toast(rs.data.errMsg, {
            title: "Error",
            variant:"danger",
            solid: true,
          })  
        }
        

      })
    },
    onApply(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let settings = Object.assign({}, this.settings)
      settings.services = this.settings.services.filter(x=>x.selected)

      NPORT.editPort({ids: ids, settings: settings}).then(rs=>{
        this.proxies.forEach(x=>{
          if(ids.includes(x._id))
          {
            x.keyport = settings.keyport
            x.rotate = settings.rotate
            x.country = settings.country
            x.services = settings.services
            x.status = settings.status
            x.proxy_type = settings.proxy_type
          }
        })
      })
    },
    onDelete(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      
      if(ids.length <= 0)
        return
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          NPORT.deletePort(ids).then(rs=>{
            this.proxies = this.proxies.filter(x=>{
              return !ids.includes(x._id)
            })
          })
          
        }
      })
    },
    clonePort(id){
      let nport = (this.proxies.map(x=>parseInt(x.port)).sort().pop())+1
      console.log(nport)
      let listK = this.proxies.filter(x=>x.keyport==id.keyport).map(x=>x.nameport).sort().pop()
      let namep = parseInt(listK.split('_')[1])+1
      let nameport = id.nameport.split('_')[0]+'_'+namep
      NPORT.clonePort({id: id._id, nameport: nameport, port: nport }).then(rs=>{
        if(rs.data.success){
          
          this.$bvToast.toast(`New Port Add ${nport.port}`, {
            title: nport.port,
            variant:"success",
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: true
          })
          this.proxies.push(rs.data.data)
          this.$store.dispatch('auth/get_uinfo')
          
        }else{
          this.$bvToast.toast(`Clone Port Error: ${rs.data.errMsg}`, {
            title: id.port,
            variant:"danger",
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: true
          })
        }
      })
    },
    changeIp(id){
      NPORT.changeIp(id._id).then(rs=>{
        if(rs.data.success){
          this.$bvToast.toast(`IP changed`, {
            title: id.port,
            variant:"success",
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: true
          })
        }else{
          this.$bvToast.toast(`Change IP Time Error: ${rs.data.errMsg}`, {
            title: id.port,
            variant:"danger",
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: true
          })
        }
      })
    },
    onChangeKeyPort(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let settings = Object.assign({}, this.settings)
      NPORT.changeKeyport({ids: ids, keyport: settings.keyport}).then(rs=>{
        this.proxies.forEach(x=>{
          if(ids.includes(x._id))
          {
            x.keyport = settings.keyport
          }
        })
      })

    },
    onChangeGeo(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let settings = Object.assign({}, this.settings)
      console.log(settings)
      ids.forEach(x=>{
        setTimeout(()=>{
          NPORT.changeGeo({
            id: x,
            country: settings.country,
            ctsett: settings.ctsett,
            state: settings.state,
            city: settings.city
          }).then(rs=>{
            if(rs.data.success){
              let cport = this.proxies.find(c=>c._id == x)
              cport.country = settings.country
              cport.ctsett = settings.ctsett
              cport.state = settings.state
              cport.city = settings.city
              this.$bvToast.toast(`Geo changed`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }else{
              this.$bvToast.toast(`Change Geo Error: ${rs.data.errMsg}`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }
          })
        },1000)
      })
    },
    onChangeRoatate(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let settings = Object.assign({}, this.settings)

      ids.forEach(x=>{
        setTimeout(()=>{
          NPORT.changeRotate({
            id: x,
            rotate: settings.rotate
          }).then(rs=>{
            if(rs.data.success){
              let cport = this.proxies.find(c=>c._id == x)
              cport.rotate = settings.rotate

              this.$bvToast.toast(`Rotate Time changed`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }else{
              this.$bvToast.toast(`Change Rotate Time Error: ${rs.data.errMsg}`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }
          })
        },1000)
      })
    },
    onChangeServices(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let settings = Object.assign({}, this.settings)

      ids.forEach(x=>{
        console.log(this.tempPost.services)
        setTimeout(()=>{
          NPORT.changeServices({
            id: x,
            services: this.tempPost.services
          }).then(rs=>{
            if(rs.data.success){
              let cport = this.proxies.find(c=>c._id == x)
              cport.services = this.tempPost.services

              this.$bvToast.toast(`Service changed`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }else{
              this.$bvToast.toast(`Change Service Error: ${rs.data.errMsg}`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }
          })
        },1000)
      })
    },
    onStatusChange(evt){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      //console.log(ids)
      //let settings = Object.assign({}, this.settings)

      ids.forEach(x=>{
        setTimeout(()=>{
          NPORT.changeStatus({
            id: x,
            status: evt
          }).then(rs=>{
            if(rs.data.success){
              let cport = this.proxies.find(c=>c._id == x)
              cport.status = evt

              this.$bvToast.toast(`Status changed`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }else{
              this.$bvToast.toast(`Status change Error: ${rs.data.errMsg}`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }
          })
        },1000)
      })
    },
    onExport(){
      let ids = this.proxies.filter(x=>x.selected)
      let data_export = []
      ids.forEach(x=>{
        data_export.push(`ip.fastproxy.vip:2086:zone-${this.$store.getters['auth/userInfo'].username}-port-${x.port}:${this.$store.getters['auth/userInfo'].proxy_pass}`)
      })

      var a = document.createElement("a");
      var json = data_export.join('\n'),
      blob = new Blob([json], {type: "octet/stream"}),
      url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = "export.txt";
      a.click();
      window.URL.revokeObjectURL(url);
    },
    onRandomKeyport(){
      const strs = "qwertyuioplkjhgfdsazxcvbnmQWERTYUIOPLKJHGFDSAZXCVBNM0123456789!@#$^*()_+=";
      var str = "";
      for (let index = 0; index < 16; index++) {
          str += strs[Math.floor(Math.random() * strs.length)];
      }
      this.tempPost.keyport = str
      
    },
    onHidePort(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      ids.forEach(x=>{
        setTimeout(()=>{
          NPORT.deletePort({
            id: x,
            status: false
          }).then(rs=>{
            if(rs.data.success){
              let cport = this.proxies.find(c=>c._id == x)
              cport.hide = true
              let index = this.proxies.findIndex(c=>c._id == x)
              this.proxies.splice(index,1)
              this.$bvToast.toast(`Port deleted`, {
                title: cport.port,
                variant:"success",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }else{
              this.$bvToast.toast(`Delete port Error: ${rs.data.errMsg}`, {
                title: cport.port,
                variant:"danger",
                toaster: "b-toaster-bottom-center",
                solid: true,
                appendToast: true
              })
            }
          })
        },1000)
      })
    },
    onRenewal(){
      let ids = this.proxies.filter(x=>x.selected).map(x=>x._id)
      let total = ids.length
      let price = this.$store.getters['auth/settings'].price_per_port
      let amount = total * price
      this.$swal({
        title: 'Are you sure?',
        text: `You will renew ${total} port with ${amount}$`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Continue',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          NPORT.rewnewPort(ids).then(rs=>{
            if(rs.data.success){
              this.$swal({
                icon: 'success',
                title: 'Renew!',
                text: 'Your port has been renew.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.$store.dispatch('auth/get_uinfo')
              ids.forEach(x=>{
                let c = this.proxies.find(y=>y._id == x)
                this.proxies.find(y=>y._id == x).expired = this.addDays(new Date(c.expired),30)
              })
            }else{
              this.$swal({
                icon: 'danger',
                title: 'Error',
                text: rs.data.errMsg,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
          
        }
      })
    },
    selectAll(){
      this.$refs.table.$refs['item-rows'].forEach(x=>{
        this.proxies[parseInt(x.$attrs['aria-rowindex']) - 1].selected = true
      })


      //console.log(this.filter)
      // this.proxies.forEach(x=>x.selected = true)
      this.$nextTick(() => {      
        this.$refs.table.refresh()
        this.onSelected()
      })
    },
    clearSelect(){
      this.proxies.forEach(x=>x.selected = false)
      this.$nextTick(() => {      
        this.$refs.table.refresh()
        this.onSelected()
      })
    },
    formatBytes(bytes) {
      var marker = 1000; // Change to 1000 if required
      var decimal = 2; // Change as required
      var kiloBytes = marker; // One Kilobyte is 1024 bytes
      var megaBytes = marker * marker; // One MB is 1024 KB
      var gigaBytes = marker * marker * marker; // One GB is 1024 MB
      var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
      // return bytes if less than a KB
      if(bytes < kiloBytes) return bytes + " Bytes";
      // return KB if less than a MB
      else if(bytes < megaBytes) return(bytes / kiloBytes).toFixed(decimal) + " KB";
      // return MB if less than a GB  ``
      else if(bytes < gigaBytes) return(bytes / megaBytes).toFixed(decimal) + " MB";
      // return GB if less than a TB
      else if(bytes < teraBytes) return (bytes / gigaBytes).toFixed(decimal) + " GB";
      
      else return (bytes / teraBytes).toFixed(decimal) + " TB";
    },
    getstateState(ct){
      let state = targetState.find(x=>  x.countrycode == ct) ? targetState.find(x=>  x.countrycode == ct)['state'] : null
      if(state){
        this.lstate = state
      }else{
        this.lstate = []
      }
      
    },
    getCity(ct){
      let state = targetCity.find(x=>  x.countrycode == ct) ? targetCity.find(x=>  x.countrycode == ct)['cities'] : null
      if(state){
        this.lcity = state
      }else{
        this.lcity = []
      }
      
    },
    changeCtSett(sl,ct){
      //console.log(ct)
      switch(sl){
        case 0:
          this.settings.state = ""
          this.settings.city = ""
          this.tempPost.state = ""
          this.tempPost.city = ""
          break;
        case 1:
          this.getstateState(ct)
          if(this.settings.state == ''){
            this.settings.state = this.lstate[0]
            this.tempPost.state = this.lstate[0]
          }
          
          this.settings.city = ""
          
          this.tempPost.city = ""
          break;
        case 2:
          this.getCity(ct)
          if(this.settings.city == ''){
            this.settings.city = this.lcity[0]
            this.tempPost.city = this.lcity[0]
          }
          
          this.settings.state = ""
          this.tempPost.state = ""
          
          break;
      }
    },
    getDate(c){
      let d = new Date(c)
      let t = d.getTime() - new Date(Date.now()).getTime()


      let diffDay =  (t / (1000 * 3600 * 24)).toFixed(0)
      return diffDay+" days"
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>