<template>
  <b-card style="height: 50rem;">
    <b-card-header class="pb-50">
      <b-card-title>White IP</b-card-title>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-success"
          class="btn-icon "
          @click="saveIp"
        >
          <feather-icon icon="SaveIcon" />
        </b-button>
    </b-card-header>
    <br>
    <b-card-body>
      <b-form-textarea
        id="textarea-state"
        v-model="text"
        placeholder="Enter ip line by line"
        rows="20"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BOverlay, BCard, BTooltip, BCardHeader, BCardTitle, BCol,BRow, BForm,
  BFormTextarea, BListGroup, BListGroupItem, BMedia, BMediaAside, BMediaBody, BCardText
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import RepositoryFactory from '../../../api/RepositoryFactory'
let NPORT = RepositoryFactory.get('snport')

export default {
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BCard,
    BTooltip,
    BCardHeader,
    BCardTitle,
    BCol,BRow,
    BForm,
    BFormTextarea,
    BListGroup,
    BListGroupItem,
    BMedia, BMediaAside, BMediaBody,
    VuePerfectScrollbar,
    BCardText
  },
  data(){
    return {
      text: ""
    }
  },
  directives: {
    Ripple,
  },
  created(){
    NPORT.fetch().then(rs=>{
      let p = rs.data.data
      if(p.length > 0){
        this.text = p[0].white_ip.join('\n')
      }else{
        this.text = ''
      }
    }).catch(e=>{

    })
  },
  methods:{
    saveIp(){
      console.log('save ip')
      let data = this.text.split('\n').filter(x=> this.ValidateIPaddress(x))
      NPORT.saveIp(data).then(rs=>{ 
        this.text = data.join('\n')
        this.$bvToast.toast(`White IP Saved`, {
            title: 'Save!',
            variant:"success",
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: true
        })

      })
    },
    ValidateIPaddress(ipaddress) 
    {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress))
          {
            return (true)
          }
        return (false)
        }
      }
    }
</script>

<style>

</style>
